
.items_intoroduction {
    padding-top: 160px;
  }
  
  .intro_title {
    margin-left: 160px;
    font-size: 40px;
    letter-spacing: 14px;
  }
  
  .intro_sub_title {
    font-family: 'EBGaramond-Regular';
    margin-left: 162px;
    font-size: 18px;
    letter-spacing: 6px;
    margin-bottom: 40px;
  }
  
  .item {
    margin-top: 18vh;
    /* height: 10vh; */
  }
  
  .item_name {
    font-family: 'EBGaramond-Regular';
    font-size: 5.5vh;
    letter-spacing: 1.2vw;
    line-height: 6.5vh;
  }
  
  .item_sub_name {
    font-size: 22px;
    letter-spacing: 0.6vw;
  }
  
  .item_discription {
    line-height: 44px;
    letter-spacing: 0.2vw;
    font-size: 20px;
  }
  
  .item_pinky {
    left: 57%;
    position: absolute;
    margin: 10vh 4vw 0 0;
    z-index: 2;
  }
  
  .item_engage {
    left: 10%;
    position: absolute;
    margin-right: 4vw;
    margin-top: 0vh;
    z-index: 2;
  }
  
  .item_marrige {
    left: 57%;
    position: absolute;
    margin-right: 4vw;
    margin-top: 0vh;
    z-index: 2;
  }
  
  .item_baby {
    left: 10%;
    position: absolute;
    margin-right: 4vw;
    margin-top: 0vh;
    z-index: 2;
  }
  /** end **/
  
  /** item-background **/
  .back_left {
    width: 70%;
  }
  .back_pinky {
    display: flex;
    justify-content: center;
    margin: 30vh auto;
  }
  .pinky_a, .pinky_b, .pinky_c,
  .engage_a, .engage_b, .engage_c,
  .marriage_a, .marriage_b, .marriage_c,
  .baby_a, .baby_b, .baby_c {
    transition: opacity 3s;
    -moz-transition: opacity 3s;
    -webkit-transition: opacity 3s;
    -o-transition: opacity 3s;
    z-index: 1;
  } 
  
  .pinky_a {
    width: 60vw;
    height: 60vh;
    background-image: url("../image/_D7A0479.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0.8vh 0.8vh 0.8vh 0;

  }
  .pinky_b {
    width: 70%;
    height: 35vh;
    /* margin-left: 25.4vh; */
    background-image: url("../image/Links/_D7A9993.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    margin-right: 0.8vh;
  }
  .pinky_c {
    width: 45vw;
    height: 35vh;
    background-image: url("../image/Links/_D7A0478.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 45vh 5vh 0.8vh 0;
  }
  
  .back_engage {
    display: flex;
    justify-content: space-between;
    margin: 30vh auto;
  }
  
  .engage_a {
    background-image: url("../image/issho1.jpg");
    width: 40vw;
    height: 40vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0.8vh 0.8vh 0.8vh 5vh;
    margin-top: 45vh;
  }
  .engage_b {
    background-image: url("../image/Links/_D7A0080.jpg");
    width: 60vw;
    height: 65vh;
    margin: 0.8vh 0vw 0.8vh 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .engage_c {
    background-image: url("../image/issho6.jpg");
    width: 40vw;
    height: 40vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .back_marriage {
    display: flex;
    justify-content: space-between;
    margin: 30vh auto;
  }
  
  .marriage_a {
    width: 60vw;
    height: 60vh;
    background-image: url("../image/_D7A0290.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0.8vh 0.8vh 0.8vh 0;
  }
  .marriage_b {
    width: 30vw;
    height: 40vh;
    margin: 0vh 0.8vw 0.8vh 0;
    margin-left: 40vh;
    background-image: url("../image/Links/_D7A0250.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    float: right;
    margin-right: 0.8vh;
  }
  .marriage_c {
    width: 45vw;
    height: 35vh;
    background-image: url("../image/Links/_D7A0398.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 45vh 5vh 0.8vh 0;
  }
  
  .back_baby {
    display: flex;
    justify-content: center;
    margin: 30vh auto 25vh;
  }
  
  .baby_a {
    background-image: url("../image/Links/_NS_1169.jpg");
    width: 40vw;
    height: 40vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0.8vh 0.8vh 0.8vh 5vh;
    margin-top: 45vh;
  }
  .baby_b {
    background-image: url("../image/Links/180413_ISSHO_0111501.jpg");
    width: 60vw;
    height: 65vh;
    margin: 0.8vh 0vw 0.8vh 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .baby_c {
    background-image: url("../image/Links/_NS_1152.jpg");
    width: 40vw;
    height: 40vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  /** end **/
  