.modal_input {
    border: none;
    border-bottom: grey 1px solid;
    width: 100%;
    color: grey;
    margin-bottom: 32px;
  }
.btn_wrapper {
    display: flex;
    justify-content: center;
}
.modal_btn {
    border: 1px solid grey;
    padding: 3px 24px;
    border-radius: 30px;
    font-size: 16px;
    width :124px;
    margin: 8px;
}
.modal_btn:focus {
    outline: none;
}
.modal_btn:hover {
    background-color: #4a4a4a;
    color: white;
}

@media screen and (min-width: 0px) and (max-width: 920px) {
  .modal_input {
    border: none;
    border-bottom:grey 1px solid; 
    width: 300px;
  }
  .modal_btn {
    background-color: white;
  }
}