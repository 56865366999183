body {
  /* background-color: #f2e9e3; */
}

.msg_wrapper {
  background-color: #f2e9e3;
  padding-top: 80px;
}
.m_container {
    text-align: center;
    margin: 120px 20px;
}

.title_area {
    margin-bottom: 80px;
}

.text-contract {
    font-size: 12px;
    margin: 0px 10px; 
}

.get_message_area {
  text-align: left;
  background-color: white;
  padding: 20px;
  margin: 0 auto;
  width: 80vw;
  animation: fadeIn 3s ease 0s 1 normal;
  -webkit-animation: fadeIn 2s ease 0s 1 normal;
  }
 
.get_message_area p{
  margin-bottom: 0;
}

@keyframes fadeIn {
0% {opacity: 0}
100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
0% {opacity: 0}
100% {opacity: 1}
}

.home_area {
  text-align: center;
  margin-bottom: 80px;
}
.home {
  padding: 10px 20px;
  background-color: #fffffe;
  width: 200px;
  height: 40px;
  border-radius:20px;
  color: black;

}
.home a {
  text-decoration: none;
}

@media screen and (min-width: 420px) and (max-width: 920px) {
  .m_container {
    text-align: center;
    margin: 100px 20px;
  }

}
@media screen and (min-width: 911px) {
  .m_container {
    text-align: center;
    margin: 100px 20px;
  }
  .get_message_area {
    width: 60vw;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #fffffe;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@media screen and (min-width: 420px) and (max-width: 920px) {
    .m_container {
        margin: 100px;
    }
}

@media screen and (min-width: 911px) {
    .m_container {
        margin: 100px;
    }
}