@media screen and (min-width: 0px) and (max-width: 920px) {

/** item introduction **/

  .items_intoroduction {
    padding-top: 80px;
  }

  .intro_title {
    margin-left: 0;
    font-size: 32px;
    letter-spacing: 2.5vw;
    margin-bottom: 20px;
    text-align: center;
  }

  .intro_sub_title {
    font-family: 'EBGaramond-Regular';
    margin-left: 0;
    text-align: center;
    font-size: 14px;
    letter-spacing: 5px;
  }

  .item {
    margin-top: 8vh;
  }

  .item_name {
    font-family: 'EBGaramond-Regular';
    font-size: 32px;
    letter-spacing: 1.5vw;
    line-height: 40px;
    margin-bottom: 8px;
  }

  .item_sub_name {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 20px;
  }

  .item_discription {
    line-height: 24px;
    letter-spacing: 3px;
    font-size: 16px;
    margin-top: 20px;
  }

  /** end **/

  /** item-background **/
  .pinky_a, .pinky_b, .pinky_c,
  .engage_a, .engage_b, .engage_c,
  .marriage_a, .marriage_b, .marriage_c,
  .baby_a, .baby_b, .baby_c {
    transition: opacity 3s;
    -moz-transition: opacity 3s;
    -webkit-transition: opacity 3s;
    -o-transition: opacity 3s;
  } 

  .pinky_a {
    width: 350px;
    height: 250px;
    float: right;
    background-image: url("../image/_D7A0479.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 8px 0;
  }

  .item_pinky {
    margin-top: 240px;
    left: 13%;
    position: absolute;
    margin-right: 0vw;
  }

  .back_pinky {
    clear: both;
    display: flex;
    justify-content: center;
    margin: 0 auto 120px auto;
  }

  .pinky_b {
    width: 49%;
    height: 160px;
    background-image: url("../image/Links/_D7A9993.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 2px;
    margin-top: 190px;
  }

  .pinky_c {
    width: 49%;
    height: 160px;
    background-image: url("../image/Links/_D7A0478.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 230px 0 0 2px;
  }


  .engage_a {
    background-image: url("../image/Links/_D7A0080.jpg");
    width: 350px;
    height: 300px;
    float: left;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 8px 0;
  }

  .item_engage {
    margin-top: 280px;
    left: 13%;
    position: absolute;
    margin-right: 0vw;
  }

  .back_engage {
    clear: both;
    display: flex;
    justify-content: center;
    margin: 0 auto 120px auto;
  }


  .engage_b {
    background-image: url("../image/issho1.jpg");
    width: 49.5%;
    height: 160px;
    margin-right: 2px;
    margin-top: 260px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .engage_c {
    background-image: url("../image/Links/_D7A9954.jpg");
    width: 49.5%;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 240px 0 0 2px;
  }



 

  .marriage_a {
    width: 350px;
    height: 250px;
    float: right;
    background-image: url("../image/_D7A0290.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 8px 0;
  }

  .item_marrige {
    margin-top: 230px;
    left: 13%;
    position: absolute;
    margin-right: 0vw;
  }
  .back_marriage {
    clear: both;
    display: flex;
    justify-content: center;
    margin: 0 auto 120px auto;
  }
  .marriage_b {
    width: 49%;
    height: 160px;
    background-image: url("../image/Links/_D7A0250.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 230px 0 0 2px;
  }
  .marriage_c {
    background-image: url("../image/Links/_D7A0398.jpg");
    width: 49%;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 250px 0 0 2px;
  }

 

  .baby_a {
    background-image: url("../image/Links/180413_ISSHO_0111501.jpg");
    width: 350px;
    height: 250px;
    float: left;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 8px 0;
  } 
  .item_baby {
    margin-top: 240px;
    left: 13%;
    position: absolute;
    margin-right: 0vw;
  }
  .back_baby {
    clear: both;
    display: flex;
    justify-content: center;
    margin: 0 auto 24px auto;
  }

  .baby_b {
    background-image: url("../image/Links/_NS_1169.jpg");
    width: 49%;
    height: 140px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 240px 0 0 2px;
  }
  .baby_c {
    background-image: url("../image/Links/_NS_1152.jpg");
    width: 49%;
    height: 160px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 260px 0 0 2px;
  }
  /** end **/


}
