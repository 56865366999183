/** hero header **/

.hd_navigation {
  position: absolute;
  width: 100%;
  height: 50px;
  padding: 0 86px 0 24px;
  top: 0;
  height: 65px;
  background-color: none;
  position: fixed;
  z-index: 10; 
}

.hd_navi_menu {
  display: flex;
  list-style: none;
  float: right;
}

.hd_navi_menu li {
  margin: 20px 30px;
  padding-top: 10px; 

}

.hd_navi_menu li a {
  color: #222222;
  font-size: 16px;
}

.hd_logo {
  float: left;
  height: 5vh;
  width: 18vw;
  margin-left: 20px;
  background-image: url('../image/logo_black.svg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 3vh;
}

.scroll-down {
  margin: 0 auto;
  min-height: 4vh;
  width: 100vw;
  padding-left: 12vw;
}

.scroll-down p {
  color: white;
  text-align: center;
  font-size: 2vh;
  padding-top: 16px;
  margin: 0;
}

.scroll-icon {
  background-image: url("../image/arrow_down.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 8px auto;
  height: 12px;
  width: 24px;
}

.hd_sns {
  padding: 3vh 156px 0 0;
  display: flex;
  width: 200px;
  justify-content: space-around;
}

.hd_down_wrapper {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  margin: 70vh auto 0;
}

/** end **/

.App-header {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  background-image: url("../image/_D7A0290.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 19vh 0 0 0;
}

.App-title-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 30vw;
  padding: 0 1vw;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
}

.App-title {
  color: white;
  font-size: 22px;
}

.App-logo {
  margin-top: 40px;
  width: 25vw;
}

/** end **/

/** concept **/
.conceptArea {
  width: 98vw;
  height: 540px;
  margin: 20px auto;
  padding-left: 0vw;
  background-color: #f2e9e3;
  display: flex;
  justify-content: space-around;
}
.service-left {
  margin-top: 64px;
  padding-left: 146px;
  width: 60%;
}

.concept {
  font-family: 'EBGaramond-Regular';
  font-size: 18px;
  padding-top: 28px;
  letter-spacing: 6px;
}

.concept-title {
  font-size: 40px;
  padding: 2vh 0px;
  line-height: 80px;
  padding-top: 2vh;
  letter-spacing: 8px;
}


.concept-detail {
  font-size: 20px;
  padding-bottom: 3vh;
  line-height: 40px;
  letter-spacing: 0.3vw;
}

.service-right {
  float: right;
  margin-top: 360px;
  margin-left: 15vw;
  width: 40vw;
}
.concept_link {
  display: table;
  width: 240px;
  background-color: white;
  padding-right: 20px;
  border: 1px solid grey;
}
.concept_link:hover {
  color: grey;
}
.concept_link_text {
  display: table-cell;
  vertical-align: middle;
  height: 60px;
  background-color: white;
  font-size: 16px;
  padding: 0 0.5vw 0 1.5vw;
}
.arrow {
  display: table-cell;
  vertical-align: middle;
  width: 8px;
  height: 8px;
  background-image: url("../image/arrow.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

/** end **/

.movie_a {
  margin: auto;
  width: 100%;
  padding: 80px 160px 0 160px;
}
.movie_detail {
  letter-spacing: 0.25vw;
  font-size: 20px;
  margin-bottom: 56px;
  line-height: 40px;
}
.movie_title br {
  display: none;
}
.movie_area {
  height: 560px;
  width: 100%;
  text-align: center;
  margin-left: 5vh auto;
}
iframe {
  height: 100%;
  width: 100%;
  padding: 0px;
}

.contract_address_group {
  display: flex;
  justify-content: space-around;
  padding: 60px 160px;
}

.contract_group {
  /* margin-left: 20px; */
}

.contract_items {
  display: block;
  justify-content: none;
  margin-top: 20px;
}
.ring_item {
  width: 250px;
  display: flex;
  justify-content: space-between;
  margin-left: 0vw;
}

.ring_name {
  font-size: 16px;
  margin: 0 1.4vw;
}

.ring_contract {
  font-size: 18px;
  background-color: whitesmoke;
  border-radius: 20px;
  padding: 8px 20px;
  width: 500px;
  height: 44px;
  text-align: center;
  letter-spacing: 0.8px;
}

.copy_btn {
  border: none;
  border-radius: 50%;
  width: 2vw;
  height: 2vh;
  margin: 1vh 0 0 0vw;
  background-image: url("../image/copy.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  /* outline: none; */
}
.copy_btn:focus,
.get_message_btn:focus {
  outline: none;
}

.input_area {
  width: 420px;
  margin: 0 100px 200px auto;
}

.message_input_area {
  margin-top: auto;
  margin-bottom: 10px;
  text-align: center;
}

.message_area {
  width: 340px;
  padding: 20px;
  background-color: white;
  border: whitesmoke 1px solid;
}

.message_input {
  border: none;
  border-bottom: grey 1px solid;
  width: 100%;
  display: block;
  color: grey;
  margin-bottom: 24px;
}

.get_message_btn {
  border: 1px solid grey;
  padding: 5px 20px;
  border-radius: 30px;
  font-size: 16px;
  width :196px;
}

::-webkit-input-placeholder {
  font-size: 1.6vh;
  letter-spacing: 2px;
}

/** end **/

/** pinky-ring **/
.rings {
  margin-top: 80px; 
  width: 100%;
  height: 320px;
  background-image: url("../image/180413_ISSHO_0111504.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
/** end **/

/** item introduction **/

/** crowdfunding-area **/

.crowdfunding-area {
  margin: 10vh auto 0 auto;
  text-align: center;
}

.crowdfunding {
  letter-spacing: 0.6vw;
  font-family: 'EBGaramond-Regular';
}

.crowdfunding_title {
  margin-top: 6vh;
  font-size: 40px;
  line-height: 72px;
  letter-spacing: 0.5vw;
}

.crowdfunding_description {
  margin: 6vh 0;
  line-height: 46px;
  letter-spacing: 2px;
  margin-bottom: 120px;
  font-size: 20px;
}

.crowdfunding_board:hover {
  background-color: whitesmoke;
}


.crowdfunding_board {
  /* display: flex;
  justify-content: center; */
  border: 1px solid grey;
  height: 400px;
  width: 80vw;
  margin: auto;
}
.crowdfunding_wrapper {
  display: flex;
  justify-content: center;
  height: 200px;
  margin: 100px auto;
}
.crowdfunding_issho_logo {
  background-image: url("../image/logo_black_large.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 300px;
  margin: 50px 24px;
}
.crowdfunding_versus {
  margin: 50px 24px;
  height: 100px;
  width: 24px;
  background-image: url("../image/x.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  /* margin-top: 17vh; */
}

.crowdfunding_cf_logo {
  background-image: url("../image/Links/campfire.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100px;
  width: 300px;
  margin: 50px 24px;
}

/** end **/

/** fotter **/

.footer {
  background-color: white;
  margin: 0px;
  padding: 6vh 0 6px 0;
  margin-top: 8vh;
  border-top: 1px solid grey;
  width: 100%;
}

.footer_logo {
  margin-bottom: 6vh;
  height: 9vh;
  background-image: url("../image/logo_black_large.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.fotter_menu {
  margin: 20px;
  display: flex;
  justify-content: center;
}

.fotter_menu span{
  border: none;
} 

.fotter_menu_left {
  padding: 0vh 2vw;
  border-left: 1px solid grey;
  height: 3vh;
  font-size: 2vh;
  vertical-align: middle;
  margin: 0;
}

.fotter_menu_none {
  padding: 0vh 2vw;
  border-left:none;
  height: 3vh;
  font-size: 2vh;
  vertical-align: middle;
  margin: 0;
}

.fotter_menu a:hover {
  color: gray;
  text-decoration: none;
}
.sns {
  margin: auto;
  padding: 30px;
  display: flex;
  width: 8vw;
  justify-content: space-around;
}

.facebook {
  width: 27px;
  height: 27px;
  margin-right: 24px;
  background-image: url("../image/facebook.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.instagram {
  width: 27px;
  height: 27px;
  margin-right: 24px;
  background-image: url("../image/instagram.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.twitter {
  width: 27px;
  height: 27px;
  background-image: url("../image/twitter.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.copy {
  text-align: center;
  font-size: 14px;
  margin: 0px;
  /* outline: none; */
}

/** end **/

/** contract **/
.contract_area {
  margin: 40px 30px;
  text-align: center;
}

.contract_flex {
  display: flex;
  justify-content: space-around;
  width: 320px;
  margin: auto;
  margin-bottom: 20px;
}

.contract_explain {
  margin: 30px auto;
  margin-top: 60px;
}

.input_contract_area {
  width: 100%;
  height: 40px;
  margin: auto;
  background-color: white;
}
.input_contract {
  width: 90%;
  height: 40px;
  margin: auto;
  border: none;
  outline: 0;
  border: 0px;
  font-size: 12px;
  text-align: center;
}

.submit_area {
  margin: 30px;
  text-align: center;
}

.btn_send {
  padding: 0px;
  background-color: #fffffe;
  width: 200px;
  height: 40px;
  border-radius: 20px;
}

.contact_thx {
  text-align: center;
}
/** end **/

/** contact **/
.contact_area {
  margin: 80px 30px;
}

.contact_title {
  text-align: center;
  font-size: 32px;
}

form {
  margin: auto;
}
/** end **/

/** start **/
.start_area {
  margin: 80px 30px;
  text-align: center;
  font-size: 28px;
}

/** end **/

@media screen and (min-width: 0px) and (max-width: 920px) {
  .hd_navi_menu,
  .App-title {
    display: none;
  }

  .hd_sns a {
    display: none;
  }

  .hd_navigation {
    position: fixed;
    width: 80%;
    height: 4vh;
    padding: 1vh 5vw 0 1vw;
    top: 0;
    height: 7vh;
  }
  .hd_logo {
    float: left;
    height: 10vh;
    width: 30vw;
    margin-left: 2vw;
    background-image: url("../image/logo_white.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 0vh;
    position: fixed;
  }

  .App-logo {
    width: 60vw;
    margin: auto;
    margin-top: 0vh;
  }

  .App-title-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 100vw;
    padding: 0 1vw;
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
  }
  .scroll-down {
    margin: 0 auto;
    min-height: 4vh;
    width: 100vw;
    padding-left: 0;
  }
  .hd_sns {
    display: none;
  }

  .scroll-down p {
    color: white;
    text-align: center;
    font-size: 2vh;
  }

  .scroll-icon {
    background-image: url("../image/arrow_down.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 1vh auto;
    height: 3vh;
    width: 8vw;
  }

  /** concept **/
  .conceptArea {
    width: 95%;
    height: 480px;
    margin: 10px auto;
    padding: 32px 0 0 0;
    background-color: #f2e9e3;
    display: block;
  }

  .service-left {
    margin-top: 0;
    padding-right: 0;
    padding-left: 32px;
  }

  .concept {
    font-size: 14px;
    padding-top: 0;
    padding-left: 0;
    letter-spacing: 5px;
    font-family: 'EBGaramond-Regular';
  }

  .concept-title {
    font-size: 24px;
    padding-bottom:20px;
    line-height: 44px;
    letter-spacing: 4px;
    width: 85vw;
  }

  .concept-title br {
    display: inline;
  }

  .concept-detail {
    font-size: 16px;
    padding-bottom: 20px;
    line-height: 30px;;
    letter-spacing: 4px;
    padding-left: 0vw;
    width: 80vw;
  }

  .concept-detail br {
    display: none;
  }

  .service-right {
    float: none;
    margin: 0 auto 0 10vw;
    display: block;
    width: 80vw;
  }

  .service-btn {
    font-size: 0.8vh;
    border: 1px solid grey;
    padding: 0.4vh 1vw;
    width: 20vh;
    text-align: center;
    background-color: white;
  }
  .concept_link {
    display: table;
    width: 240px;
    background-color: white;
    padding-right: 20px;
    border: 1px solid grey;
  }
  .concept_link:hover {
    color: grey;
  }
  .concept_link_text {
    display: table-cell;
    vertical-align: middle;
    height: 56px;
    background-color: white;
    font-size: 16px;
    width: 12vw;
    padding: 0 0.5vw 0 24px;
  }
  .arrow {
    display: table-cell;
    vertical-align: middle;
    width: 2px;
    height: 2px;
    background-image: url("../image/arrow.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  /** end **/

  /** movie message **/
  .movie_message_area {
    margin: 10vh auto;
    width: 100vw;
  }

  .movie_a {
    margin: auto;
    width: 100vw;
    padding: 0 42px;
    /* padding-right: 0; */
  }

  .movie_detail {
    letter-spacing: 3px;
    padding: 0 0vw;
    font-size: 16px;
    margin-bottom: 42px;
    width: 80vw;
  }

  .movie_detail br {
    display: none;
  }

  .movie_area {
    height: 150px;
  }

  iframe {
    height: 140px;
  }

  .contract_address_group {
    display: block;
    justify-content: none;
    padding: 12px 24px;
  }

  .contract_group {
    padding-right: 0vw;
    margin: 0;
  }

  .contract_items {
    display: block;
    justify-content: none;
    margin: 2vh auto;
    width: 320px;
  }

  .ring_item {
    width: 200px;
    display: flex;
    justify-content: space-between;
    margin-left: 8%;
  }

  .ring_name {
    font-size: 14px;
    margin: 0.8vh 1.4vw;
    letter-spacing: 0.2px;
  }

  .ring_contract {
    font-size: 14px;
    background-color: whitesmoke;
    border-radius: 20px;
    padding: 1vh 2vw;
    text-align: center;
    margin: 0 auto;
    width: 320px;
    text-align: center;
    letter-spacing: 0.1px;
  }

  .copy_btn {
    border: none;
    border-radius: 50%;
    width: 6vw;
    height: 2.7vh;
    margin: 1vh 0 0 0vw;
    background-image: url("../image/copy.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .input_area {
    width: 300px;
    margin: 60px auto;
    display: block;
  }
  
  .message_input_area {
    text-align: center;
  }
  
  .message_area {
    width: 300px;
    padding: 10px;
    background-color: white;
    margin: 20px auto;
    border: whitesmoke 1px solid;
  }

  .message_input {
    border: none;
    border-bottom:grey 1px solid; 
    width: 300px;
    display: block;
    /* color: gray; */
    /* margin: 0 auto; */
    /* text-align: center; */
  }
  
  .get_message_btn {
    display: block;
    border: 1px solid grey;
    padding: 0.4vh 1.6vw;
    border-radius: 20px; 
    width:45vw;
    font-size:1.5vh;
    margin: 0 auto;
    text-align: center;
    background-color: white;
  }
  
  ::-webkit-input-placeholder{
    font-size: 1.8vh;
    letter-spacing: 2px;
  }
    /** end **/

  /** pinky-ring **/
  .rings {
    margin-top: 10px;
    width: 100vw;
    height: 140px;
    background-image: url("../image/180413_ISSHO_0111504.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  /** end **/

  /** crowdfunding-area **/

  .crowdfunding-area {
    margin: 0 auto;
    text-align: center;
    margin: 15vh 0 ;
  }

  .crowdfunding {
    font-size: 14px;
    letter-spacing: 5px;
    font-family: 'EBGaramond-Regular';
  }

  .crowdfunding_title {
    margin-top: 6.5vh;
    font-size: 24px;
    line-height: 50px;
    letter-spacing: 0.5vw;
  }

  .crowdfunding_description {
    margin: 6vh 8vw;
    line-height: 44px;
    letter-spacing: 1px;
    font-size: 18px;
    text-align: left;
  }

  .crowdfunding_description br {
    display: none;
  }

  .crowdfunding_board {
    height: 140px;

  }
  .crowdfunding_wrapper {
    height: 100px;
    margin: 20px auto;
  }
  .crowdfunding_issho_logo {
    height: 50px;
    width: 100px;
    margin: 25px 8px;
  }
  .crowdfunding_versus {
    height: 20px;
    width: 50px;
    margin: 40px 4px;
  }
  .crowdfunding_cf_logo {
    height: 50px;
    width: 100px;
    margin: 25px 8px;
  }

  /** end **/

  /** fotter **/
  .footer {
    padding: 8vh 2vh 2vh 2vh;
    margin-top: 100px;
  }

  .fotter_menu {
    margin: 0 auto;
    display: block;
    justify-content: none;
  }
  .fotter_menu a {
    border: none;
    font-size: 2.5vh;
    margin: 4vh auto;
  }

  .fotter_menu_none {
    letter-spacing: 0.8vw;
    font-size: 2vh;
    padding: 2vh 2vw;
    height: 1vh;
    display: block;
    margin: 2vh auto;
    text-align: center;
    border: none;
  }
  .fotter_menu_none span {
    display: none;
  }
  .fotter_menu_left span {
    display: none;
  }
  .fotter_menu_left {
    letter-spacing: 0.8vw;
    font-size: 2vh;
    padding: 2vh 2vw;
    border-left: none;
    height: 1vh;
    display: block;
    margin: 2vh auto;
    text-align: center;
  }

  .sns {
    margin: 4vh auto 1vh auto;
    padding: 20px;
    display: flex;
    width: 30vw;
    justify-content: space-between;
  }

  .sns a {
    margin: 0 0.8vh;
  }

  .facebook {
    /* margin-right: 20px; */
    margin: 0 10px;
  }

  .instagram {
    /* margin-right: 20px; */
    margin: 0 10px;
  }

  .twitter {
    /* margin-right: 20px; */
    margin: 0 10px;
  }

  .copy {
    text-align: center;
    font-size: 8px;
    margin: 0px;
  }
  /** end **/

  /** contract **/
  .contract_area {
    margin: 40px 30px;
    text-align: center;
  }

  .contract_flex {
    display: flex;
    justify-content: space-around;
    width: 320px;
    margin: auto;
    margin-bottom: 20px;
  }

  .contract_explain {
    margin: 30px auto;
    margin-top: 60px;
  }

  .input_contract_area {
    width: 100%;
    height: 40px;
    margin: auto;
    background-color: white;
  }
  .input_contract {
    width: 90%;
    height: 40px;
    margin: auto;
    border: none;
    outline: 0;
    border: 0px;
    font-size: 12px;
    text-align: center;
  }

  .submit_area {
    margin: 30px;
    text-align: center;
  }

  .btn_send {
    padding: 0px;
    background-color: #fffffe;
    width: 200px;
    height: 40px;
    border-radius: 20px;
  }
  .contact_thx {
    text-align: center;
  }
  /** end **/

  /** contact **/
  .contact_area {
    margin: 80px 30px;
  }

  .contact_title {
    text-align: center;
    font-size: 32px;
  }

  form {
    margin: auto;
  }
  /** end **/

  /** start **/
  .start_area {
    margin: 80px 30px;
    text-align: center;
    font-size: 28px;
  }
}
/** end **/

@media screen and (min-width: 911px) {
  .concept-area {
    margin: 40px 30px;
    max-width: 400px;
    margin: auto;
  }
  .howtouse_area {
    max-width: 400px;
    margin: auto;
  }
  .contract-address {
    font-size: 12px;
    background: white;
    padding: 10px;
    margin-top: 20px;
  }
  .input_contract_area {
    max-width: 450px;
  }
  .input_contract {
    max-width: 420px;
  }
  .recruit_area {
    max-width: 400px;
    margin: auto;
  }
  .contact_area {
    margin: 40px 30px;
  }
  .sample_contract {
    font-size: 12px;
    margin: 0px 10px;
  }
}
