
@font-face {
  font-family: 'TsukuARdGothic-Bold';
  src: url('../fonts/FOT-TsukuARdGothicStd-B.otf')
}
@font-face {
  font-family: 'A1MinchoStd-Bold';
  src: url('../fonts/A-OTF_A1_Mincho_Std Bold.otf')
}
@font-face {
  font-family: 'Shuuei3Std-Medium';
  src: url('../fonts/A-OTF-Shuuei3Std-Medium.otf')
}
@font-face {
  font-family: 'GaramondPremrPro';
  src: url('../fonts/GaramondPremrPro-Smbd.otf')
}
@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: url('../fonts/HVD_Fonts-BrandonGrotesque-Bold.otf')
}
@font-face {
  font-family: 'YuMin-Medium';
  src: url('../fonts/Yu_Mincho_Medium.otf')
}
@font-face {
  font-family: 'EBGaramond-Regular';
  src: url('../fonts/EBGaramond-Regular.ttf')
}


html {
  min-width: 100vw;
}
body {
  background-color: white;
  color: #595757;
  font-family: 'A1MinchoStd-Bold', 'GaramondPremrPro','sans-serif';
  line-height: 30px;
  letter-spacing: 0.1em;
  min-width: 100vw;
}

input {
  font-family: 'A1MinchoStd-Bold', 'GaramondPremrPro','sans-serif';
}

button {
  font-family: 'A1MinchoStd-Bold', 'GaramondPremrPro','sans-serif';
  /* outline: none; */
}

button:hover {
  background-color: grey;
  color: white;
}

a {
  text-decoration: none;
  color: #595757;
}
a:hover {
  text-decoration: none;
}

input[type="text"], 
input[type="password"], 
textarea, 
select{
    outline: none;
}
